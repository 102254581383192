import { graphql } from 'gatsby'
import React, { FC } from 'react'
import SEO from '../components/seo/seo'
import H from '../design/H'
import Layout from '../layout'

interface SimplePageProps {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
      }
      html: string
    }
  }
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
      }
      html
    }
  }
`

const SimplePageTemplate: FC<SimplePageProps> = ({ data }) => {
  const page = data.markdownRemark
  return (
    <Layout>
      <SEO title={page.frontmatter.title} />

      <div className="container mx-auto px-4 my-8">
        <H>{page.frontmatter.title}</H>
      </div>
      <div className="container mx-auto px-4 mb-8">
        <div
          className="prose break-words"
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
      </div>
    </Layout>
  )
}

export default SimplePageTemplate
